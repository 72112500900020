<template>
  <div class="content-tree text-xs overflow-y-auto h-full flex">
    <assets-explorer
      v-if="project && activeFolder"
      :assets="assets"
      :project="project"
      :embedded-mode="true"
      :override-select="true"
      :active-folder="activeFolder"
      :action-mode="'select'"
      :restricted-action-types="restrictedActionTypes"
      @select-asset="onSelectAsset"
    />

    <!-- <div class="content-tree text-xs overflow-y-scroll pb-4 bg-gray-800" style="">
      <div class="font-sans text-gray-200 mx-2 flex items-center justify-between sticky top-0 bg-gray-800 z-40 p-2 border-b border-gray-600">
        <div class="left-side text-xs ">
          <fa-icon icon="magnifying-glass" class="text-gray-300" />
          <input type="search" placeholder="Filter content" class="input-text px-2 py-1 m-1 bg-gray-700 text-gray-200 border-gray-600 rounded">
        </div>
        <div class="right-side flex">
          <div class="cursor-pointer p-1"><fa-icon icon="plus" class="text-sm text-gray-500 hover:text-white fa-fw ml-1" /></div>
          <div class="cursor-pointer p-1"><fa-icon icon="upload" class="text-sm text-gray-500 hover:text-white fa-fw ml-1" /></div>
          <div class="cursor-pointer p-1"><fa-icon icon="folder-plus" class="text-sm text-gray-500 hover:text-white fa-fw ml-1" /></div>
        </div>
      </div>
      <div class="mx-2 my-2">
        <div class="px-2">
          <div class="text-xs my-1 text-gray-300 select-none">
            <div class="flex justify-between pb-1 border-b border-gray-700">
              <div class="left-side truncate mr-2 flex items-center"><span class="inline-block px-1 cursor-pointer text-gray-400 hover:text-blue-500">
                <fa-icon icon="caret-down" size="xs" class="text-gray-300" />
                </span><a href="#" class="hover:underline cursor-pointer hover:text-white px-1 py-1 flex items-center">
                  <fa-icon icon="folder" class="mr-2 text-blue-500" size="2x" />
                  Folder<span class="ml-1">(5)</span></a>
              </div>
              <div class="right-side flex justify-between items-center mr-2">
              </div>
            </div>
            <div class="ml-4" style="">
              <div class="text-xs my-1 text-gray-300 select-none">
                <div class="flex justify-between pb-1 border-b border-gray-700">
                  <div class="left-side truncate mr-2"><a href="#" class="hover:underline cursor-pointer hover:text-white px-1 py-1 flex items-center">
                        <div class="w-8 h-6 mr-3 object-cover flex justify-center ">
                          <img src="https://previz-cdn1.s3.amazonaws.com/p/1b7aa92e-2521-4423-81dc-5cf4388481ab/assets/ce7cba0c-2979-4b8b-bed6-b62ff08d7df6/1588590933/thumbnail/200_150_crop/cZCU3vA0qW2IUmkb-1588590946.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&amp;X-Amz-Algorithm=AWS4-HMAC-SHA256&amp;X-Amz-Credential=AKIAIOP2GDGCUH7GOFTA%2F20200612%2Fus-east-1%2Fs3%2Faws4_request&amp;X-Amz-Date=20200612T140603Z&amp;X-Amz-SignedHeaders=host&amp;X-Amz-Expires=432000&amp;X-Amz-Signature=1f8a614cf2b8c0513cb2db0c21e3dd0cc37ee8bd1cf10fe79b67aad4131918bd" alt="asset.name" class="rounded-sm"></div>
                        Video File 01
                      </a>
                  </div>
                  <div class="right-side flex justify-between items-center mr-2">
                    <div class="cursor-pointer">
                      <fa-icon icon="magnifying-glass" class="ml-1 text-gray-200" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-xs my-1 text-gray-300 select-none">
                <div class="flex justify-between pb-1 border-b border-gray-700">
                  <div class="left-side truncate mr-2"><a href="#" class="hover:underline cursor-pointer hover:text-white px-1 py-1 flex items-center">
                        <div class="rounded-sm w-8 h-6 mr-3 object-cover flex justify-center items-center bg-blue-500">
                          <fa-icon icon="file-video" class="text-white" /></div>
                        Video File 01
                      </a>
                  </div>
                  <div class="right-side flex justify-between items-center mr-2">
                    <div class="cursor-pointer">
                      <fa-icon icon="magnifying-glass" class="ml-1 text-gray-200" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-xs my-1 text-gray-300 select-none">
                <div class="flex justify-between pb-1 border-b border-gray-700">
                  <div class="left-side truncate mr-2">
                    <a href="#" class="hover:underline cursor-pointer hover:text-white px-1 py-1 flex items-center">
                          <div class="rounded-sm w-8 h-6 mr-3 object-cover flex justify-center items-center bg-teal-500">
                            <fa-icon icon="file-audio" class="text-white" /></div>
                          Audio File 01
                        </a>
                  </div>
                  <div class="right-side flex justify-between items-center mr-2">
                    <div class="cursor-pointer">
                      <fa-icon icon="magnifying-glass" class="ml-1 text-gray-200" />
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="px-2">
          <div class="text-xs my-1 text-gray-300 select-none">
            <div class="flex justify-between pb-1 border-b border-gray-700">
              <div class="left-side truncate mr-2">
                <a href="#" class="hover:underline cursor-pointer hover:text-white px-1 py-1 flex items-center">
                      <div class="rounded-sm w-8 h-6 mr-3 object-cover flex justify-center items-center bg-blue-500">
                        <fa-icon icon="file-video" class="text-white" /></div>
                      Video File 01
                    </a>
              </div>
              <div class="right-side flex justify-between items-center mr-2">
                <div class="cursor-pointer">
                  <fa-icon icon="magnifying-glass" class="ml-1 text-gray-200" />
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div> -->
  </div>
</template>
<script>
const AssetsExplorer = () => import('@components/assets/assets-explorer.vue')

export default {
  name: 'ContentTree',

  components: {
    'assets-explorer': AssetsExplorer
  },

  computed: {

    restrictedActionTypes () {
      return ['image', 'video', 'composition']
    },

    assets: function () {
      return this.$store.getters['assets/assets']
    },

    project: function () {
      return this.$store.getters['project/project']
    },

    activeFolder: function () {
      return this.$store.getters['assets/activeSet'](this.project.id)
    }

  },

  methods: {
    onSelectAsset (asset) {
      if (!this.isReady) return
      console.log('ON SELECT asset')
      // this.$bus.$emit('timeline:new-module', { assetId: asset.id })
    }

  }
}
</script>
